@import "app";

body {
    font-family: Roboto; }

a {
    transition: all 0.2s;
    &:hover {
        text-decoration: none; } }

form {
    input[type="radio"]:focus {
        outline: 0 !important; } }

input:not([type="radio"]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input, select {
        font-size: 16px !important; }
    .data-picker {
        cursor: default;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0); } }

.rel {
    position: relative; }

/* ----------------------------- Header ------------------------------------ */
.header {
    padding-bottom: 26px;
    background-color: #d94a3d; }

.header-white-block {
    padding-bottom: 14px;
    background-color: #f7f7f7;
    &__logo {
        margin-top: 13px;
        text-align: left;
        @include bp(sm) {
            text-align: center; }
        @include bp(md) {
            margin-left: 71px; } }
    &__adress {
        margin-top: 15px;
        text-align: left;
        @include bp(sm) {
            text-align: center; }
        @include bp(md) {
            margin-top: 40px; } }
    &__contact {
        margin-top: 20px;
        position: absolute;
        top: 15px;
        right: 15px;
        margin-top: 0;
        text-align: right;
        @include bp(sm) {
            position: static;
            margin-top: 20px;
            text-align: center; }
        @include bp(md) {
            margin-top: 50px; } }
    &__phone {
        margin-bottom: 3px; } }

.col-sm-12_not-relative {
    position: static;
    @include bp(sm) {
        position: relative; } }

.logo {
    img {
        display: inline-block;
        width: 100px;
        @include bp(500px) {
            width: 150px; }
        @include bp(sm) {
            width: 186px; } } }

.header-adress {
    display: inline-block;
    color: #22323f;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    font-size: 1.8rem;
    br {
        display: none; }
    @include bp(sm) {
        br {
            display: inline; } }
    &__line-1 {
        @include bp(sm) {
            font-size: 2rem; } }
    &__line-2 {
        padding-top: 0.2em;
        @include bp(sm) {
            font-size: 2.3rem; } } }

.phone {
    color: #a6382e;
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    @include bp(500px) {
        font-size: 3rem; }
    @include bp(sm) {
        font-size: 3.4rem; }
    @include bp(lg) {
        font-size: 3.9rem; } }

.red-brand-button {
    position: relative;
    display: inline-block;
    padding: 0.631em 1.421em 0.2em;
    border-bottom: solid 9px #c74034;
    background-color: #d94a3d;
    color: #f7f7f7;
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: uppercase;
    @include bp(500px) {
        font-size: 1.8rem; }
    @include bp(sm) {
        font-size: 1.9rem; }
    span {
        position: relative;
        border-bottom: solid 1px transparent;
        z-index: 10;
        transition: all 0.2s; }
    &:after {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: #ce413a;
        z-index: 5;
        transition: all 0.2s; }
    &:hover,
    &:focus {
        color: #f7f7f7;
        text-decoration: none;
        background-color: #e14f41;
        border-color: #c74034;
        &:after {
            background-color: #d5463f; }
        span {
            border-color: #f7f7f7; } }
    &:active {
        background-color: #cf4033;
        border-color: #be362a;
        &:after {
            background-color: #c6362f; } }
    &_in-header {
        font-size: 1rem;
        @include bp(400px) {
            font-size: 1.2rem; }
        @include bp(md) {
            font-size: 1.4rem; }
        @include bp(lg) {
            font-size: 1.9rem; } } }

.menu {
    min-height: 50px;
    padding: 10px 0;
    background: url('../img/navigation.svg') 0 0 no-repeat;
    background-size: contain;
    @include bp(700px) {
        padding: 0; }
    &__items {
        max-width: 570px;
        margin: 0 auto;
        padding: 0;
        @include bp(sm) {
            max-width: 670px; }
        @include bp(md) {
            min-width: 770px; } }
    &__item {
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        list-style: none;
        @include bp(700px) {
            margin: 0;
            display: inline-block;
            width: 24%; } }
    &__link {
        display: inline-block;
        padding: 0.5em 1em;
        font-size: 1.6rem;
        line-height: 1.5;
        color: #eae8dc;
        &:hover,
        &:focus {
            display: inline-block;
            background-color: #1b2933;
            color: #eae8dc; }
        &:focus {
            text-decoration: none;
            background-color: inherit; }
        @include bp(700px) {
            padding: 0 1em;
            line-height: 50px; }
        @include bp(sm) {
            font-size: 1.8rem; }
        @include bp(md) {
            font-size: 2rem; } } }


/* ----------------------------- About section ------------------------------------ */
.about-section {
    padding-top: 35px;
    padding-bottom: 40px;
    background: url('../img/s1-bg.jpg') center center no-repeat;
    background-size: cover;
    &__heading {
        margin: 0;
        color: #d94a3d;
        font-size: 3rem;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        @include bp(sm) {
            font-size: 4.8rem; } }
    &__video {
        max-width: 594px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 10px 20px;
        text-align: center; }
    &__info {
        margin: 0;
        color: #eae8dc;
        font-size: 1.8rem;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        @include bp(sm) {
            font-size: 2.2rem; } } }

.main-video-block {
    position: relative;
    background-color: #fff;
    &_cover {
        background-color: #ddd;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #fff url('../img/video-bg.png') center center no-repeat;
            background-size: cover;
            z-index: 50;
            transition: all 0.2s; }
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #fff; }
        &:hover {
            &:before {
                cursor: pointer;
                opacity: 0.9; } } } }

/* ----------------------------- Shipping section ------------------------------------ */
.shipping-section {
    &__heading {
        margin: 0 auto 30px; }
    &__button-line {
        text-align: center;
        margin: 20px 0 32px; } }

.brand-ribbon-heading {
    min-height: 66px;
    max-width: 630px;
    padding-top: 16px;
    background: url('../img/podlozhka1.svg') 0 0 no-repeat;
    background-size: contain;
    color: #f7f7f7;
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    @include bp(sm) {
        padding-top: 12px;
        font-size: 3.6rem; }
    &_shipping {
        padding-left: 50px;
        padding-right: 50px; } }

.shipping-info-item {
    text-align: center;
    &_col-fix {
        @include bp(lg) {
            padding: 0;
            width: 18%; } }
    &__icon {
        max-width: 100%;
        height: 126px;
        &_truck {
            width: 107px; }
        &_coin {
            width: 118px; }
        &_delivery {
            width: 153px; } }
    &__heading {
        margin-top: 20px;
        margin-bottom: 25px;
        color: #d94a3d;
        font-size: 2.3rem;
        font-weight: 500;
        @include bp(sm) {
            min-height: 75px; } }
    &__text {
        margin-bottom: 35px;
        color: #1d1c1a;
        font-size: 1.6rem;
        font-weight: 400;
        text-align: left;
        @include bp(sm) {
            margin-bottom: 0; } } }


/* ----------------------------- Red line section ------------------------------------ */
.red-line-section {
    background-color: #d94a3d; }

.brand-line-text {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding: 12px 0;
    overflow: hidden;
    text-align: center;
    color: #f7f7f7;
    font-size: 3rem;
    line-height: 1;
    font-weight: 400;
    @include bp(lg) {
        width: 1000px;
        padding: 40px 0 31px; }
    &__logo {
        position: absolute;
        display: inline-block;
        height: 208px;
        width: 385px;
        top: -54px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        @include bp(lg) {
            top: -60px;
            left: 96px;
            transform: translateX(0); } }
    &__spanner-left {
        position: relative;
        z-index: 5;
        display: block;
        @include bp(lg) {
            position: absolute;
            left: 20px;
            top: 41px; } }
    &__spanner-right {
        position: relative;
        z-index: 5;
        display: block;
        padding-top: 64px;
        @include bp(lg) {
            position: relative;
            display: block;
            padding-left: 385px;
            padding-top: 0;
            z-index: 10; } }
    // &__spanner
    //     position: relative
    //     z-index: 5
    //     display: block
    //     +bp(lg)
    //         display: inline
    //     &:last-of-type
    //         padding-top: 35px
    //         +bp(lg)
    //             padding-top: 0
 }    //             padding-left: 250px

/* ----------------------------- Russia map section ------------------------------------ */
.russia-map-section {
    position: relative;
    overflow: hidden;
    background-color: #22323f;
    &__text {
        position: relative;
        margin: 0 auto;
        padding: 50px 30px;
        text-align: center;
        color: #f7f7f7;
        font-size: 2.2rem;
        line-height: 1.2;
        font-weight: 500;
        text-transform: uppercase;
        z-index: 15;
        @include bp(sm) {
            width: 630px;
            padding: 245px 0 250px; }
        @include bp(md) {
            font-size: 3rem; } }
    &__lines {
        position: absolute;
        top: 0;
        left: -35px;
        width: 100%;
        height: 100%;
        z-index: 13;
        @include bp(lg) {
            background: url('../img/lines.svg') center center no-repeat; } }
    &__triangle {
        position: absolute;
        background: url('../img/triangle.svg') 0 0 no-repeat;
        opacity: 0.31;
        z-index: 5;
        &_1 {
            top: -180px;
            left: 15%;
            width: 300px;
            height: 900px; }
        &_2 {
            top: 50px;
            left: 38%;
            width: 300px;
            height: 900px;
            transform: rotate(45deg); }
        &_3 {
            top: -450px;
            right: 8%;
            width: 300px;
            height: 900px;
            transform: rotate(-150deg); } }
    &__map {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        @include bp(sm) {
            background: url('../img/map.svg') center -80px no-repeat;
            background-size: 100% 120%; } } }

/* ----------------------------- Slider section ------------------------------------ */
.slider-section {
    .carousel-caption {
        text-align: center;
        @include bp(lg) {
            position: absolute;
            bottom: 25px;
            left: 215px;
            width: 530px; } }
    &__heading {
        background-color: #d94a3d;
        padding: 1em 0.76em;
        color: #eae8dc;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.5rem;
        @include bp(sm) {
            font-size: 2rem; }
        @include bp(lg) {
            font-size: 2.6rem; } }
    &__text {
        display: inline-block;
        width: 90%;
        padding: 0.5em 4.1875em;
        background-color: #f7f7f7;
        text-align: left;
        color: #201f1b;
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: 400;
        @include bp(sm) {
            font-size: 1.4rem; }
        @include bp(lg) {
            font-size: 1.6rem; } } }

.carousel {
    .item {
        height: 425px;
        background-position: center center;
        background-size: cover; } }

.carousel-control {
    .icon-right,
    .icon-left {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 53px;
        height: 125px; }
    .icon-right {
        right: 25%;
        background-image: url("../img/arrow-right.png"); }
    .icon-left {
        left: 25%;
        background-image: url("../img/arrow-left.png"); }
    &.right,
    &.left {
        background-image: none; } }


/* ----------------------------- About us section ------------------------------------ */
.about-us-section {
    &__heading {
        margin: 0 auto 45px; }
    @include bp(sm) {
        padding-bottom: 20px; }
    @include bp(lg) {
        padding-bottom: 59px; } }

.about-us-item {
    padding: 0;
    margin-bottom: 40px;
    @include bp(sm) {
        margin-bottom: 0; }
    &__box-image {
        position: relative;
        width: 30%;
        margin: 0 auto;
        &:before {
            content: '';
            display: inline-block;
            padding-top: 100%; }
        @include bp(sm) {
            width: 100%; } }
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background-color: #22323f;
        text-align: center;
        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 70%;
            height: 70%;
            margin: auto; }
        &_map {
            img {
                width: 90%;
                height: 90%; } } }
    &__heading {
        padding: 0;
        margin: 0;
        margin-top: 30px;
        text-align: center;
        color: #22323f;
        font-size: 3rem;
        font-weight: 900;
        font-size: 2rem;
        @include bp(sm) {
            font-size: 2.5rem; }
        @include bp(lg) {
            font-size: 3rem; } } }


/* ----------------------------- Contacts section ------------------------------------ */
.contacts-section {
    min-height: 450px;
    background-color: #d94a3d;
    color: #f7f7f7;
    font-weight: 400;
    font-size: 1.7rem;
    &__map {
        position: relative;
        padding: 0;
        height: 450px;
        iframe {
            border: none; } }
    &__map-mobile-overlay {
        position: absolute;
        display: table;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: #d94a3d;
        opacity: 0.5;
        @include bp(sm) {
            display: none; }
        span {
            display: table-cell;
            vertical-align: middle;
            text-align: center; } }
    &__pane {
        padding: 20px 10px;
        background-color: #c74034;
        text-align: center;
        @include bp(sm) {
            min-height: 450px;
            padding: 45px 10px 0; }
        @include bp(lg) {
            padding: 45px 30px 0; } }
    &__index {
        margin: 0;
        line-height: 1;
        @include bp(sm) {
            font-size: 2.5rem; } }
    &__city {
        margin-bottom: 15px;
        @include bp(sm) {
            margin-bottom: 25px;
            font-size: 2rem; } }
    &__adress {
        margin-bottom: 15px;
        @include bp(sm) {
            margin-bottom: 25px;
            font-size: 2.3rem; } } }

.mode-time-pane {
    max-width: 295px;
    margin: 0 auto;
    padding: 20px 25px 20px;
    border: 3px dashed #cec9ae;
    border-radius: 10px;
    color: #f7f7f7;
    font-weight: 400;
    line-height: 1;
    font-size: 1.5rem;
    &__text {
        margin-bottom: 20px;
        @include bp(sm) {
            font-size: 2.4rem; } }
    &__day {
        margin-bottom: 5px;
        @include bp(sm) {
            font-size: 1.8rem; } }
    &__time {
        margin-bottom: 20px;
        font-weight: 500;
        @include bp(sm) {
            font-size: 3.5rem; }
        &:last-of-type {
            margin-bottom: 0; } } }


/* ----------------------------- Footer ------------------------------------ */
.footer {
    padding-bottom: 20px;
    background-color: #22323f;
    text-align: center;
    .row {
        position: relative; }
    &__brand {
        position: relative;
        z-index: 10;
        @include bp(sm) {
            text-align: right; } }
    &__contacts {
        position: relative;
        z-index: 10;
        margin-top: 25px;
        @include bp(sm) {
            margin-top: 80px;
            text-align: left; }
        @include bp(md) {
            margin-top: 134px; } } }

.tirangle-shape {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    @include bp(sm) {
        border-color: #1a2a36 transparent transparent transparent;
        border-style: solid;
        border-width: 60px 550px 0px 200px; }
    @include bp(md) {
        border-width: 60px 650px 0px 300px; }
    @include bp(lg) {
        border-width: 60px 800px 0px 350px; } }

.footer-brand {
    padding: 0;
    color: #f7f7f7;
    font-size: 1.3rem;
    font-weight: 300;
    @include bp(sm) {
        font-size: 1.8rem; }
    &__logo {
        margin-top: 50px; }
    &__copyright {
        margin-top: 30px; }
    &__studio {
        a {
            color: #cec9ae;
            &:hover {
                opacity: 0.8; } } } }

.footer-contacts {
    color: #f7f7f7;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.666;
    @include bp(sm) {
        font-size: 1.8rem; }
    .fa {
        display: inline-block;
        width: 30px; }
    .fa-phone {
        padding-left: 2px; }
    .fa-map-marker {
        padding-left: 3px; } }


/* ----------------------------- Modal ------------------------------------ */
.modal {
    &-footer {
        text-align: center; }
    .form-group {
        position: relative; } }

.sa-modal {
    .modal-title {
        text-align: center;
        color: #d94a3d;
        h4 {
            font-size: 2rem; } }
    label {
        color: #22323f; } }

.asterisk {
    padding: 10px;
    color: #d94a3d; }

.form-group_required {
    label:after {
        content: ' *';
        color: #d94a3d; } }

.input-daterange {
    .form-control[readonly] {
        background-color: #fff; } }

.picker {
    right: 0;
    &__holder {
        right: 0;
        left: 0;
        margin: auto; } }


/* ----------------------------- For backend ------------------------------------ */
.redactor-editor {
    color: #000; }



/*------------------------------ calc ------------------------------------------- */
.calc {
    font-size: 16px;
    padding-top: vw(70); }
.calc__button {
    font-size: 16px;
    margin: auto;
    width: em(490,16);
    height: em(75,16);
    position: relative;
    z-index: 1;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
        opacity:.9 {} }
    &:before, &:after {
        display: block;
        content: '';
        position: absolute;
        width: em(70,16);
        height: em(75,16);
        top: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1; }
    &:before {
        left: 0;
        background-image: url(../img/calc_shape_left.svg); }
    &:after {
        right: 0;
        background-image: url(../img/calc_shape_right.svg); }
    &--more {
        transform: scale(.75);
        &:before {
            background-image: url(../img/more_shape_left.svg); }
        &:after {
            background-image: url(../img/more_shape_right.svg); } } }

.calc__button-text {
    background-image: url(../img/calc_shape.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(490,16);
    height: em(75,16);
    span {
        font-size: em(24,16);
        text-decoration: underline;
        color: #f7f7f7;
        text-transform: uppercase; }
    &--more {
        background-image: url(../img/shape_more.svg); } }


.calc__description {
    text-align: center;
    font-size: em(14,16);
    color: #22323f;
    line-height: 1.2;
    max-width: 370px;
    width: 100%;
    margin: auto; }
.calc__close {
    display: inline-block;
    color: #ccc;
    font-size: em(14,16);
    line-height: 1.2;
    margin-top: em(20,14);
    cursor: pointer;
    &:hover {
        text-decoration: underline; } }
.calc__form {
    margin-top: vw(46); }

.calc__total {
    color: #d5463f;
    text-align: center;
    margin-bottom: em(9,16);
    font-weight: 700;
    span {
        &:first-child {
            font-size: em(30,16); }
        &:last-child {
            font-size: em(16,16); } } }
.calc__helper {
    text-align: center;
    font-weight: 700;
    font-size: em(12,16);
    line-height: 1.2;
    color: #22323f; }

.calc__pricing {
    font-size: em(14,16);
    color: #22323f;
    text-align: center;
    font-weight: 700;
    margin-bottom: em(9,14);
    span {
        font-size: em(20,14); } }



/*------------------------------ field ------------------------------------------ */

.field {
    position: relative;
    font-size: 16px;
    margin-bottom: em(32,16);
    label {
        display: inline-block;
        font-weight: 700;
        font-size: em(14,16);
        color: #22323f;
        margin-bottom: em(12,14); } }

.field__input {
    display: block;
    appearance: none;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: em(11, 16) em(10, 16) em(10, 16);
    color: #d5463f;
    line-height: 1;
    &--select {
        background-image: url(../img/angle-down.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 11px) 15px;
        background-size: 9px 9px; } }

.field__helper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    box-shadow: 0px 4px 18px 0px rgba(0,0,0,.36);
    background: #fff;
    z-index: 20;
    border: 1px solid #ccc;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: all .25s ease;
    transform: translateY(10px);
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); } }

.field__option {
    padding: 4px 12px;
    font-size: em(14,16);
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:last-child {
        border-bottom: 1px solid transparent; }
    &:hover {
        background: rgba(#ccc, .4); } }


.mt-60 {
    margin-top: vw(60); }

.mb-80 {
    margin-bottom: vw(80); }

.calc__submit {
    margin-top: 30px; }


@media screen and (max-width: 991px) {
    .calc__submit {
        text-align: center;
        margin-bottom: 80px; }
    .calc__helper {
        margin-bottom: 50px; } }

@media screen and (max-width: 550px) {
    .calc__button {
        font-size:  10px; } }

@media screen and (max-width: 767px) {
    .calc__submit .red-brand-button_in-header {
        font-size: 16px; } }

.l-features {
    font-size: 16px;
    padding: em(82,16) 0 em(10,16); }

.feature {
    font-size: 16px;
    margin-bottom: em(71,16); }

.feature__title {
    text-transform: uppercase;
    font-size: em(24,16);
    color: #22323f;
    font-weight: 500;
    margin-bottom: em(22,24); }

.feature__content {
    line-height: 1.5;
    color: #22323f; }


.special-title {
    font-size: 36px;
    color: #d94a3d;
    text-transform: uppercase;
    text-align: center;
    line-height: em(67, 36);
    display: block;
    margin: auto auto em(83,36) auto;
    background-image: url(../img/specials.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: em(630, 36);
    height: em(67, 36);
    position: relative;
    top: -2px; }

.special {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    overflow: hidden;
    max-width: 90%;
    margin: auto auto em(52,16) auto; }

.special__city {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-grow: 0;
    &:before {
        display: block;
        position: absolute;
        width: 50%;
        z-index: 3;
        content: '';
        background: #d94a3d;
        left: 0;
        top: 0;
        height: em(100,16); }
    &:last-child:before {
        left: auto;
        right: 0; }
    .fa {
        font-size: em(96,16);
        margin-bottom: em(27,96); }
    span {
        font-size: em(24,16);
        line-height: 1.25;
        color: #f7f7f7; } }

.special__icon {
    display: inline-block;
    background: #d94a3d;
    text-align: center;
    padding: 0 5px;
    position: relative;
    z-index: 4; }

.special__price {
    color: #f7f7f7;
    font-weight: 700;
    font-size: em(30,16);
    flex-grow: 1;
    text-align: center;
    left: 0;
    top: 0;
    padding-bottom: em(9,30);
    z-index: 1;
    position: relative;
    &:after {
        display: block;
        width: 100vw;
        height: 0;
        top: 100%;
        left: -25vw;
        content: '';
        position: absolute;
        border-bottom: 3px dashed #22323f; } }

.section--red {
    background: #d94a3d; }

.section--reddark {
    background:#c74034 {}
    padding: em(39,16) 0 em(34,16);
    font-size: 16px; }

.l-info {
    margin: auto;
    max-width: em(530, 16);
    &:before, &:after {
        display: table;
        content: " "; }
    &:after {
        clear: both; } }

.l-info__icon {
    float: left;
    width: em(46,16);
    margin-right: em(18,16);
    color: #fff;
    .fa {
        line-height: em(52,46);
        font-size: em(46,16); } }

.l-info__text {
    float: left;
    color: #fff;
    font-size: em(24,16);
    line-height: 1.2;
    width: calc(100% - #{em(65, 24)}); }

.l-cargo {
    width: 100%;
    font-size: 28px;
    color: #f7f7f7;
    text-transform: uppercase;
    line-height: 80px;
    background-image: url(../img/cargo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    text-align: center;
    white-space: nowrap;
    position: relative;
    top: -2px;
    z-index: -1; }
@media screen and (max-width:1199px) and (min-width: 992px) {
    .l-cargo {
        font-size: 22px;
        line-height: 60px; } }

@media screen and (min-width:768px) and (max-width: 991px) {
    .l-cargo {
        font-size: 16px;
        line-height: 46px; }
    .service__text {
        text-align: center; }
    .service {
        max-width: 300px; } }

.service {
    font-size: 16px;
    margin: em(45,16) auto em(60,16); }
.service__image {
    margin-bottom: em(26,16);
    img {
        display: block;
        margin: auto;
        height: em(100,16); } }
.service__text {
    font-size: em(18,16);
    color: #22323f;
    line-height: 1.2; }


@media screen and (max-width: 767px) {
    .special-title {
        font-size: 18px; }
    .special {
        font-size: 8px; }
    .l-info {
        font-size: 12px; }
    .l-cargo {
        font-size: 18px;
        padding: 6px 15px;
        width: calc(100% + 30px);
        left: -15px;
        line-height: 20px;
        white-space: normal;
        background-size: cover; } }


.l-body {
    position: relative;
    max-width: 100%;
    overflow: hidden; }


.footer {
    position: relative;
    &:before {
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 100%;
        left: 0;
        background: #22323f; } }

.l-flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
    &>div {
        max-width: 40%;
        margin-bottom: 30px;
        &:first-child {
            margin-right: 50px; } } }

@media screen and (max-width: 767px) {
    .l-flex {
        flex-direction: column;
        &>div {
            max-width: 100%;
            &:first-child {
                margin-right: 0; } } } }
