// Import mixins
@import "mixins/breakpoint";
@import "mixins/mappy-breakpoints";
@import "mixins/mixins";

// Set map for breakpoints
$breakpoints: ('xs': 320px, 'sm': 768px, 'md': 992px, 'lg': 1200px, 'xlg': 1400px);

@include font-face(Roboto, '../fonts/Roboto/Roboto-Regular/roboto-regular', 400, normal, woff2 woff );
@include font-face(Roboto, '../fonts/Roboto/Roboto-Light/roboto-light', 300, normal, woff2 woff );
@include font-face(Roboto, '../fonts/Roboto/Roboto-Medium/roboto-medium', 500, normal, woff2 woff );
@include font-face(Roboto, '../fonts/Roboto/Roboto-Black/roboto-black', 900, normal, woff2 woff );


$baseFontSize: 12px;
$baseWidth: 1400;

@function em($pixels, $context: $baseFontSize) {
  @return strip-unit($pixels) / strip-unit($context) * 1em; }


@function rem($pixels, $context: $baseFontSize) {
  @return strip-unit($pixels) / strip-unit($context) * 1rem; }


@function strip-unit($num) {
  @return $num / ($num * 0 + 1); }


@function line($lineHeight, $fontSize) {
  @return strip-unit($lineHeight) / strip-unit($fontSize); }


@function vw($num) {
  @return strip-unit($num) / strip-unit($baseWidth) * 100vw; }

@function vwRel($num, $context) {
  @return strip-unit($num) / strip-unit($context) * 100vw; }
