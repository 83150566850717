@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto/Roboto-Regular/roboto-regular.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Regular/roboto-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Roboto/Roboto-Light/roboto-light.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Light/roboto-light.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Roboto/Roboto-Medium/roboto-medium.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Medium/roboto-medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Roboto/Roboto-Black/roboto-black.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Black/roboto-black.woff") format("woff");
}

body {
  font-family: Roboto;
}

a {
  transition: all 0.2s;
}

a:hover {
  text-decoration: none;
}

form input[type="radio"]:focus {
  outline: 0 !important;
}

input:not([type="radio"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.rel {
  position: relative;
}

/* ----------------------------- Header ------------------------------------ */

.header {
  padding-bottom: 26px;
  background-color: #d94a3d;
}

.header-white-block {
  padding-bottom: 14px;
  background-color: #f7f7f7;
}

.header-white-block__logo {
  margin-top: 13px;
  text-align: left;
}

.header-white-block__adress {
  margin-top: 15px;
  text-align: left;
}

.header-white-block__contact {
  margin-top: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  margin-top: 0;
  text-align: right;
}

.header-white-block__phone {
  margin-bottom: 3px;
}

.col-sm-12_not-relative {
  position: static;
}

.logo img {
  display: inline-block;
  width: 100px;
}

.header-adress {
  display: inline-block;
  color: #22323f;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  font-size: 1.8rem;
}

.header-adress br {
  display: none;
}

.header-adress__line-2 {
  padding-top: 0.2em;
}

.phone {
  color: #a6382e;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
}

.red-brand-button {
  position: relative;
  display: inline-block;
  padding: 0.631em 1.421em 0.2em;
  border-bottom: solid 9px #c74034;
  background-color: #d94a3d;
  color: #f7f7f7;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
}

.red-brand-button span {
  position: relative;
  border-bottom: solid 1px transparent;
  z-index: 10;
  transition: all 0.2s;
}

.red-brand-button:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #ce413a;
  z-index: 5;
  transition: all 0.2s;
}

.red-brand-button:hover,
.red-brand-button:focus {
  color: #f7f7f7;
  text-decoration: none;
  background-color: #e14f41;
  border-color: #c74034;
}

.red-brand-button:hover:after,
.red-brand-button:focus:after {
  background-color: #d5463f;
}

.red-brand-button:hover span,
.red-brand-button:focus span {
  border-color: #f7f7f7;
}

.red-brand-button:active {
  background-color: #cf4033;
  border-color: #be362a;
}

.red-brand-button:active:after {
  background-color: #c6362f;
}

.red-brand-button_in-header {
  font-size: 1rem;
}

.menu {
  min-height: 50px;
  padding: 10px 0;
  background: url("../img/navigation.svg") 0 0 no-repeat;
  background-size: contain;
}

.menu__items {
  max-width: 570px;
  margin: 0 auto;
  padding: 0;
}

.menu__item {
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  list-style: none;
}

.menu__link {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #eae8dc;
}

.menu__link:hover,
.menu__link:focus {
  display: inline-block;
  background-color: #1b2933;
  color: #eae8dc;
}

.menu__link:focus {
  text-decoration: none;
  background-color: inherit;
}

/* ----------------------------- About section ------------------------------------ */

.about-section {
  padding-top: 35px;
  padding-bottom: 40px;
  background: url("../img/s1-bg.jpg") center center no-repeat;
  background-size: cover;
}

.about-section__heading {
  margin: 0;
  color: #d94a3d;
  font-size: 3rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.about-section__video {
  max-width: 594px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px 20px;
  text-align: center;
}

.about-section__info {
  margin: 0;
  color: #eae8dc;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.main-video-block {
  position: relative;
  background-color: #fff;
}

.main-video-block_cover {
  background-color: #ddd;
}

.main-video-block_cover:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff url("../img/video-bg.png") center center no-repeat;
  background-size: cover;
  z-index: 50;
  transition: all 0.2s;
}

.main-video-block_cover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
}

.main-video-block_cover:hover:before {
  cursor: pointer;
  opacity: 0.9;
}

/* ----------------------------- Shipping section ------------------------------------ */

.shipping-section__heading {
  margin: 0 auto 30px;
}

.shipping-section__button-line {
  text-align: center;
  margin: 20px 0 32px;
}

.brand-ribbon-heading {
  min-height: 66px;
  max-width: 630px;
  padding-top: 16px;
  background: url("../img/podlozhka1.svg") 0 0 no-repeat;
  background-size: contain;
  color: #f7f7f7;
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.brand-ribbon-heading_shipping {
  padding-left: 50px;
  padding-right: 50px;
}

.shipping-info-item {
  text-align: center;
}

.shipping-info-item__icon {
  max-width: 100%;
  height: 126px;
}

.shipping-info-item__icon_truck {
  width: 107px;
}

.shipping-info-item__icon_coin {
  width: 118px;
}

.shipping-info-item__icon_delivery {
  width: 153px;
}

.shipping-info-item__heading {
  margin-top: 20px;
  margin-bottom: 25px;
  color: #d94a3d;
  font-size: 2.3rem;
  font-weight: 500;
}

.shipping-info-item__text {
  margin-bottom: 35px;
  color: #1d1c1a;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
}

/* ----------------------------- Red line section ------------------------------------ */

.red-line-section {
  background-color: #d94a3d;
}

.brand-line-text {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 12px 0;
  overflow: hidden;
  text-align: center;
  color: #f7f7f7;
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;
}

.brand-line-text__logo {
  position: absolute;
  display: inline-block;
  height: 208px;
  width: 385px;
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.brand-line-text__spanner-left {
  position: relative;
  z-index: 5;
  display: block;
}

.brand-line-text__spanner-right {
  position: relative;
  z-index: 5;
  display: block;
  padding-top: 64px;
}

/* ----------------------------- Russia map section ------------------------------------ */

.russia-map-section {
  position: relative;
  overflow: hidden;
  background-color: #22323f;
}

.russia-map-section__text {
  position: relative;
  margin: 0 auto;
  padding: 50px 30px;
  text-align: center;
  color: #f7f7f7;
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 15;
}

.russia-map-section__lines {
  position: absolute;
  top: 0;
  left: -35px;
  width: 100%;
  height: 100%;
  z-index: 13;
}

.russia-map-section__triangle {
  position: absolute;
  background: url("../img/triangle.svg") 0 0 no-repeat;
  opacity: 0.31;
  z-index: 5;
}

.russia-map-section__triangle_1 {
  top: -180px;
  left: 15%;
  width: 300px;
  height: 900px;
}

.russia-map-section__triangle_2 {
  top: 50px;
  left: 38%;
  width: 300px;
  height: 900px;
  transform: rotate(45deg);
}

.russia-map-section__triangle_3 {
  top: -450px;
  right: 8%;
  width: 300px;
  height: 900px;
  transform: rotate(-150deg);
}

.russia-map-section__map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

/* ----------------------------- Slider section ------------------------------------ */

.slider-section .carousel-caption {
  text-align: center;
}

.slider-section__heading {
  background-color: #d94a3d;
  padding: 1em 0.76em;
  color: #eae8dc;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.slider-section__text {
  display: inline-block;
  width: 90%;
  padding: 0.5em 4.1875em;
  background-color: #f7f7f7;
  text-align: left;
  color: #201f1b;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 400;
}

.carousel .item {
  height: 425px;
  background-position: center center;
  background-size: cover;
}

.carousel-control .icon-right,
.carousel-control .icon-left {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 53px;
  height: 125px;
}

.carousel-control .icon-right {
  right: 25%;
  background-image: url("../img/arrow-right.png");
}

.carousel-control .icon-left {
  left: 25%;
  background-image: url("../img/arrow-left.png");
}

.carousel-control.right,
.carousel-control.left {
  background-image: none;
}

/* ----------------------------- About us section ------------------------------------ */

.about-us-section__heading {
  margin: 0 auto 45px;
}

.about-us-item {
  padding: 0;
  margin-bottom: 40px;
}

.about-us-item__box-image {
  position: relative;
  width: 30%;
  margin: 0 auto;
}

.about-us-item__box-image:before {
  content: '';
  display: inline-block;
  padding-top: 100%;
}

.about-us-item__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: #22323f;
  text-align: center;
}

.about-us-item__image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 70%;
  margin: auto;
}

.about-us-item__image_map img {
  width: 90%;
  height: 90%;
}

.about-us-item__heading {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  text-align: center;
  color: #22323f;
  font-size: 3rem;
  font-weight: 900;
  font-size: 2rem;
}

/* ----------------------------- Contacts section ------------------------------------ */

.contacts-section {
  min-height: 450px;
  background-color: #d94a3d;
  color: #f7f7f7;
  font-weight: 400;
  font-size: 1.7rem;
}

.contacts-section__map {
  position: relative;
  padding: 0;
  height: 450px;
}

.contacts-section__map iframe {
  border: none;
}

.contacts-section__map-mobile-overlay {
  position: absolute;
  display: table;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: #d94a3d;
  opacity: 0.5;
}

.contacts-section__map-mobile-overlay span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.contacts-section__pane {
  padding: 20px 10px;
  background-color: #c74034;
  text-align: center;
}

.contacts-section__index {
  margin: 0;
  line-height: 1;
}

.contacts-section__city {
  margin-bottom: 15px;
}

.contacts-section__adress {
  margin-bottom: 15px;
}

.mode-time-pane {
  max-width: 295px;
  margin: 0 auto;
  padding: 20px 25px 20px;
  border: 3px dashed #cec9ae;
  border-radius: 10px;
  color: #f7f7f7;
  font-weight: 400;
  line-height: 1;
  font-size: 1.5rem;
}

.mode-time-pane__text {
  margin-bottom: 20px;
}

.mode-time-pane__day {
  margin-bottom: 5px;
}

.mode-time-pane__time {
  margin-bottom: 20px;
  font-weight: 500;
}

.mode-time-pane__time:last-of-type {
  margin-bottom: 0;
}

/* ----------------------------- Footer ------------------------------------ */

.footer {
  padding-bottom: 20px;
  background-color: #22323f;
  text-align: center;
}

.footer .row {
  position: relative;
}

.footer__brand {
  position: relative;
  z-index: 10;
}

.footer__contacts {
  position: relative;
  z-index: 10;
  margin-top: 25px;
}

.tirangle-shape {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
}

.footer-brand {
  padding: 0;
  color: #f7f7f7;
  font-size: 1.3rem;
  font-weight: 300;
}

.footer-brand__logo {
  margin-top: 50px;
}

.footer-brand__copyright {
  margin-top: 30px;
}

.footer-brand__studio a {
  color: #cec9ae;
}

.footer-brand__studio a:hover {
  opacity: 0.8;
}

.footer-contacts {
  color: #f7f7f7;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.666;
}

.footer-contacts .fa {
  display: inline-block;
  width: 30px;
}

.footer-contacts .fa-phone {
  padding-left: 2px;
}

.footer-contacts .fa-map-marker {
  padding-left: 3px;
}

/* ----------------------------- Modal ------------------------------------ */

.modal-footer {
  text-align: center;
}

.modal .form-group {
  position: relative;
}

.sa-modal .modal-title {
  text-align: center;
  color: #d94a3d;
}

.sa-modal .modal-title h4 {
  font-size: 2rem;
}

.sa-modal label {
  color: #22323f;
}

.asterisk {
  padding: 10px;
  color: #d94a3d;
}

.form-group_required label:after {
  content: ' *';
  color: #d94a3d;
}

.input-daterange .form-control[readonly] {
  background-color: #fff;
}

.picker {
  right: 0;
}

.picker__holder {
  right: 0;
  left: 0;
  margin: auto;
}

/* ----------------------------- For backend ------------------------------------ */

.redactor-editor {
  color: #000;
}

/*------------------------------ calc ------------------------------------------- */

.calc {
  font-size: 16px;
  padding-top: 5vw;
}

.calc__button {
  font-size: 16px;
  margin: auto;
  width: 30.625em;
  height: 4.6875em;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 14px;
}

.calc__button:hover {
  opacity: 0.9;
}

.calc__button:before,
.calc__button:after {
  display: block;
  content: '';
  position: absolute;
  width: 4.375em;
  height: 4.6875em;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.calc__button:before {
  left: 0;
  background-image: url(../img/calc_shape_left.svg);
}

.calc__button:after {
  right: 0;
  background-image: url(../img/calc_shape_right.svg);
}

.calc__button--more {
  transform: scale(0.75);
}

.calc__button--more:before {
  background-image: url(../img/more_shape_left.svg);
}

.calc__button--more:after {
  background-image: url(../img/more_shape_right.svg);
}

.calc__button-text {
  background-image: url(../img/calc_shape.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30.625em;
  height: 4.6875em;
}

.calc__button-text span {
  font-size: 1.5em;
  text-decoration: underline;
  color: #f7f7f7;
  text-transform: uppercase;
}

.calc__button-text--more {
  background-image: url(../img/shape_more.svg);
}

.calc__description {
  text-align: center;
  font-size: 0.875em;
  color: #22323f;
  line-height: 1.2;
  max-width: 370px;
  width: 100%;
  margin: auto;
}

.calc__close {
  display: inline-block;
  color: #ccc;
  font-size: 0.875em;
  line-height: 1.2;
  margin-top: 1.42857em;
  cursor: pointer;
}

.calc__close:hover {
  text-decoration: underline;
}

.calc__form {
  margin-top: 3.28571vw;
}

.calc__total {
  color: #d5463f;
  text-align: center;
  margin-bottom: 0.5625em;
  font-weight: 700;
}

.calc__total span:first-child {
  font-size: 1.875em;
}

.calc__total span:last-child {
  font-size: 1em;
}

.calc__helper {
  text-align: center;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1.2;
  color: #22323f;
}

.calc__pricing {
  font-size: 0.875em;
  color: #22323f;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0.64286em;
}

.calc__pricing span {
  font-size: 1.42857em;
}

/*------------------------------ field ------------------------------------------ */

.field {
  position: relative;
  font-size: 16px;
  margin-bottom: 2em;
}

.field label {
  display: inline-block;
  font-weight: 700;
  font-size: 0.875em;
  color: #22323f;
  margin-bottom: 0.85714em;
}

.field__input {
  display: block;
  appearance: none;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.6875em 0.625em 0.625em;
  color: #d5463f;
  line-height: 1;
}

.field__input--select {
  background-image: url(../img/angle-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 11px) 15px;
  background-size: 9px 9px;
}

.field__helper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.36);
  background: #fff;
  z-index: 20;
  border: 1px solid #ccc;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease;
  transform: translateY(10px);
}

.field__helper.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.field__option {
  padding: 4px 12px;
  font-size: 0.875em;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.field__option:last-child {
  border-bottom: 1px solid transparent;
}

.field__option:hover {
  background: rgba(204, 204, 204, 0.4);
}

.mt-60 {
  margin-top: 4.28571vw;
}

.mb-80 {
  margin-bottom: 5.71429vw;
}

.calc__submit {
  margin-top: 30px;
}

.l-features {
  font-size: 16px;
  padding: 5.125em 0 0.625em;
}

.feature {
  font-size: 16px;
  margin-bottom: 4.4375em;
}

.feature__title {
  text-transform: uppercase;
  font-size: 1.5em;
  color: #22323f;
  font-weight: 500;
  margin-bottom: 0.91667em;
}

.feature__content {
  line-height: 1.5;
  color: #22323f;
}

.special-title {
  font-size: 36px;
  color: #d94a3d;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.86111em;
  display: block;
  margin: auto auto 2.30556em auto;
  background-image: url(../img/specials.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 17.5em;
  height: 1.86111em;
  position: relative;
  top: -2px;
}

.special {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  max-width: 90%;
  margin: auto auto 3.25em auto;
}

.special__city {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-grow: 0;
}

.special__city:before {
  display: block;
  position: absolute;
  width: 50%;
  z-index: 3;
  content: '';
  background: #d94a3d;
  left: 0;
  top: 0;
  height: 6.25em;
}

.special__city:last-child:before {
  left: auto;
  right: 0;
}

.special__city .fa {
  font-size: 6em;
  margin-bottom: 0.28125em;
}

.special__city span {
  font-size: 1.5em;
  line-height: 1.25;
  color: #f7f7f7;
}

.special__icon {
  display: inline-block;
  background: #d94a3d;
  text-align: center;
  padding: 0 5px;
  position: relative;
  z-index: 4;
}

.special__price {
  color: #f7f7f7;
  font-weight: 700;
  font-size: 1.875em;
  flex-grow: 1;
  text-align: center;
  left: 0;
  top: 0;
  padding-bottom: 0.3em;
  z-index: 1;
  position: relative;
}

.special__price:after {
  display: block;
  width: 100vw;
  height: 0;
  top: 100%;
  left: -25vw;
  content: '';
  position: absolute;
  border-bottom: 3px dashed #22323f;
}

.section--red {
  background: #d94a3d;
}

.section--reddark {
  background: #c74034;
  padding: 2.4375em 0 2.125em;
  font-size: 16px;
}

.l-info {
  margin: auto;
  max-width: 33.125em;
}

.l-info:before,
.l-info:after {
  display: table;
  content: " ";
}

.l-info:after {
  clear: both;
}

.l-info__icon {
  float: left;
  width: 2.875em;
  margin-right: 1.125em;
  color: #fff;
}

.l-info__icon .fa {
  line-height: 1.13043em;
  font-size: 2.875em;
}

.l-info__text {
  float: left;
  color: #fff;
  font-size: 1.5em;
  line-height: 1.2;
  width: calc(100% - 2.70833em);
}

.l-cargo {
  width: 100%;
  font-size: 28px;
  color: #f7f7f7;
  text-transform: uppercase;
  line-height: 80px;
  background-image: url(../img/cargo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  white-space: nowrap;
  position: relative;
  top: -2px;
  z-index: -1;
}

.service {
  font-size: 16px;
  margin: 2.8125em auto 3.75em;
}

.service__image {
  margin-bottom: 1.625em;
}

.service__image img {
  display: block;
  margin: auto;
  height: 6.25em;
}

.service__text {
  font-size: 1.125em;
  color: #22323f;
  line-height: 1.2;
}

.l-body {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.footer {
  position: relative;
}

.footer:before {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 100%;
  left: 0;
  background: #22323f;
}

.l-flex {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.l-flex > div {
  max-width: 40%;
  margin-bottom: 30px;
}

.l-flex > div:first-child {
  margin-right: 50px;
}

@media all and (min-width: 31.25em) {
  .logo img {
    width: 150px;
  }

  .phone {
    font-size: 3rem;
  }

  .red-brand-button {
    font-size: 1.8rem;
  }
}

@media all and (min-width: 43.75em) {
  .menu {
    padding: 0;
  }

  .menu__item {
    margin: 0;
    display: inline-block;
    width: 24%;
  }

  .menu__link {
    padding: 0 1em;
    line-height: 50px;
  }
}

@media all and (min-width: 25em) {
  .red-brand-button_in-header {
    font-size: 1.2rem;
  }
}

@media all and (min-width: 48em) {
  .header-white-block__logo {
    text-align: center;
  }

  .header-white-block__adress {
    text-align: center;
  }

  .header-white-block__contact {
    position: static;
    margin-top: 20px;
    text-align: center;
  }

  .col-sm-12_not-relative {
    position: relative;
  }

  .logo img {
    width: 186px;
  }

  .header-adress br {
    display: inline;
  }

  .header-adress__line-1 {
    font-size: 2rem;
  }

  .header-adress__line-2 {
    font-size: 2.3rem;
  }

  .phone {
    font-size: 3.4rem;
  }

  .red-brand-button {
    font-size: 1.9rem;
  }

  .menu__items {
    max-width: 670px;
  }

  .menu__link {
    font-size: 1.8rem;
  }

  .about-section__heading {
    font-size: 4.8rem;
  }

  .about-section__info {
    font-size: 2.2rem;
  }

  .brand-ribbon-heading {
    padding-top: 12px;
    font-size: 3.6rem;
  }

  .shipping-info-item__heading {
    min-height: 75px;
  }

  .shipping-info-item__text {
    margin-bottom: 0;
  }

  .russia-map-section__text {
    width: 630px;
    padding: 245px 0 250px;
  }

  .russia-map-section__map {
    background: url("../img/map.svg") center -80px no-repeat;
    background-size: 100% 120%;
  }

  .slider-section__heading {
    font-size: 2rem;
  }

  .slider-section__text {
    font-size: 1.4rem;
  }

  .about-us-section {
    padding-bottom: 20px;
  }

  .about-us-item {
    margin-bottom: 0;
  }

  .about-us-item__box-image {
    width: 100%;
  }

  .about-us-item__heading {
    font-size: 2.5rem;
  }

  .contacts-section__map-mobile-overlay {
    display: none;
  }

  .contacts-section__pane {
    min-height: 450px;
    padding: 45px 10px 0;
  }

  .contacts-section__index {
    font-size: 2.5rem;
  }

  .contacts-section__city {
    margin-bottom: 25px;
    font-size: 2rem;
  }

  .contacts-section__adress {
    margin-bottom: 25px;
    font-size: 2.3rem;
  }

  .mode-time-pane__text {
    font-size: 2.4rem;
  }

  .mode-time-pane__day {
    font-size: 1.8rem;
  }

  .mode-time-pane__time {
    font-size: 3.5rem;
  }

  .footer__brand {
    text-align: right;
  }

  .footer__contacts {
    margin-top: 80px;
    text-align: left;
  }

  .tirangle-shape {
    border-color: #1a2a36 transparent transparent transparent;
    border-style: solid;
    border-width: 60px 550px 0px 200px;
  }

  .footer-brand {
    font-size: 1.8rem;
  }

  .footer-contacts {
    font-size: 1.8rem;
  }
}

@media all and (min-width: 62em) {
  .header-white-block__logo {
    margin-left: 71px;
  }

  .header-white-block__adress {
    margin-top: 40px;
  }

  .header-white-block__contact {
    margin-top: 50px;
  }

  .red-brand-button_in-header {
    font-size: 1.4rem;
  }

  .menu__items {
    min-width: 770px;
  }

  .menu__link {
    font-size: 2rem;
  }

  .russia-map-section__text {
    font-size: 3rem;
  }

  .footer__contacts {
    margin-top: 134px;
  }

  .tirangle-shape {
    border-width: 60px 650px 0px 300px;
  }
}

@media all and (min-width: 75em) {
  .phone {
    font-size: 3.9rem;
  }

  .red-brand-button_in-header {
    font-size: 1.9rem;
  }

  .shipping-info-item_col-fix {
    padding: 0;
    width: 18%;
  }

  .brand-line-text {
    width: 1000px;
    padding: 40px 0 31px;
  }

  .brand-line-text__logo {
    top: -60px;
    left: 96px;
    transform: translateX(0);
  }

  .brand-line-text__spanner-left {
    position: absolute;
    left: 20px;
    top: 41px;
  }

  .brand-line-text__spanner-right {
    position: relative;
    display: block;
    padding-left: 385px;
    padding-top: 0;
    z-index: 10;
  }

  .russia-map-section__lines {
    background: url("../img/lines.svg") center center no-repeat;
  }

  .slider-section .carousel-caption {
    position: absolute;
    bottom: 25px;
    left: 215px;
    width: 530px;
  }

  .slider-section__heading {
    font-size: 2.6rem;
  }

  .slider-section__text {
    font-size: 1.6rem;
  }

  .about-us-section {
    padding-bottom: 59px;
  }

  .about-us-item__heading {
    font-size: 3rem;
  }

  .contacts-section__pane {
    padding: 45px 30px 0;
  }

  .tirangle-shape {
    border-width: 60px 800px 0px 350px;
  }
}

@media screen and (max-width: 991px) {
  .calc__submit {
    text-align: center;
    margin-bottom: 80px;
  }

  .calc__helper {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .calc__submit .red-brand-button_in-header {
    font-size: 16px;
  }

  .special-title {
    font-size: 18px;
  }

  .special {
    font-size: 8px;
  }

  .l-info {
    font-size: 12px;
  }

  .l-cargo {
    font-size: 18px;
    padding: 6px 15px;
    width: calc(100% + 30px);
    left: -15px;
    line-height: 20px;
    white-space: normal;
    background-size: cover;
  }

  .l-flex {
    flex-direction: column;
  }

  .l-flex > div {
    max-width: 100%;
  }

  .l-flex > div:first-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 550px) {
  .calc__button {
    font-size: 10px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .l-cargo {
    font-size: 22px;
    line-height: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .l-cargo {
    font-size: 16px;
    line-height: 46px;
  }

  .service__text {
    text-align: center;
  }

  .service {
    max-width: 300px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input,
  select {
    font-size: 16px !important;
  }

  .data-picker {
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}